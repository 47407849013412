<template>
  <v-card flat class="grey lighten-3">
    <v-card-title class="pa-0 grey lighten-2">
      <v-spacer />
      <template v-if="editMode || createMode">
        <v-btn icon small :loading="loading" @click="onSaveClick">
          <v-icon color="tertiary">mdi-content-save</v-icon>
        </v-btn>
        <v-btn icon small :disabled="loading" @click="onCancelClick">
          <v-icon color="tertiary">mdi-close-circle</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn :disabled="disabled || deleting || readonly" icon small @click="onEditClick">
          <v-icon color="tertiary">mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          :disabled="disabled || readonly"
          :loading="deleting && deletedIndex === index"
          icon
          small
          @click="onRemoveClick"
        >
          <v-icon color="tertiary">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-card-title>
    <v-card-text>
      <validation-observer ref="observer">
        <v-row>
          <v-col cols="12" sm="6">
            <validation-provider :name="$t('hr.travel.detail_type')" rules="required" v-slot="{ errors }">
              <travel-detail-type-picker
                v-model="innerValue.travelDetailType"
                :disabled="viewMode"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider :name="$t('hr.travel.detail_subtype')" rules="required" v-slot="{ errors }">
              <travel-detail-subtype-picker
                v-model="innerValue.travelDetailSubType"
                :disabled="viewMode"
                :detail-type="innerValue.travelDetailType"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider :name="$t('hr.travel.begin_date')" rules="required" v-slot="{ errors }">
              <date-time-picker
                v-model="innerValue.beginDate"
                :max="innerValue.endDate || maxDate"
                :min="minDate"
                :disabled="viewMode"
                :label="$t('hr.travel.begin_date')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider :name="$t('hr.travel.end_date')" rules="required" v-slot="{ errors }">
              <date-time-picker
                v-model="innerValue.endDate"
                :min="innerValue.beginDate || minDate"
                :max="maxDate"
                :disabled="viewMode"
                :label="$t('hr.travel.end_date')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="innerValue.isRentCar"
              :disabled="viewMode || disableSwitchButtons"
              color="teal"
              :label="$t('hr.travel.is_rent_car')"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="innerValue.isShuttle"
              :disabled="viewMode || disableSwitchButtons"
              color="teal"
              :label="$t('hr.travel.is_shuttle')"
            />
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('global.description')" rules="required|max:500" v-slot="{ errors }">
              <v-textarea
                v-model="innerValue.comment"
                :disabled="viewMode"
                :label="$t('global.description')"
                rows="1"
                counter="500"
                color="tertiary"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-29 07:33:22
   * @modify date 2020-12-29 07:33:22
   * @desc TravelDetailItem
   */
  import TravelDetail from "../model/TravelDetail";
  import { TravelDetailTypeEnum } from "../enums";

  export default {
    name: "TravelDetailItem",
    components: {
      TravelDetailTypePicker: () => import("./TravelDetailTypePicker"),
      TravelDetailSubtypePicker: () => import("./TravelDetailSubtypePicker")
    },
    props: {
      value: Object,
      index: Number,
      activeIndex: Number,
      deletedIndex: Number,
      disabled: Boolean,
      loading: Boolean,
      deleting: Boolean,
      readonly: Boolean,
      minDate: String,
      maxDate: String
    },
    data: vm => ({
      innerValue: new TravelDetail()
    }),
    computed: {
      editMode() {
        return this.activeIndex === this.index;
      },
      createMode() {
        return !this.innerValue.id;
      },
      viewMode() {
        return !this.editMode && !this.createMode;
      },
      disableSwitchButtons() {
        return this.innerValue.travelDetailType !== TravelDetailTypeEnum.TRANSFER;
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(value) {
          this.innerValue = this.$helpers.cloneDeep(value);
        }
      },
      minDate(minDate) {
        if (this.$moment(this.innerValue.beginDate).isBefore(this.$moment(minDate))) {
          this.innerValue.beginDate = null;
        }
        if (this.$moment(this.innerValue.endDate).isBefore(this.$moment(minDate))) {
          this.innerValue.endDate = null;
        }
        this.$emit("update:item", this.innerValue);
      },
      maxDate(maxDate) {
        if (this.$moment(this.innerValue.beginDate).isAfter(this.$moment(maxDate))) {
          this.innerValue.beginDate = null;
        }
        if (this.$moment(this.innerValue.endDate).isAfter(this.$moment(maxDate))) {
          this.innerValue.endDate = null;
        }
        this.$emit("update:item", this.innerValue);
      }
    },
    methods: {
      onSaveClick() {
        this.$refs.observer.validate().then(valid => {
          if (valid) {
            if (this.createMode) {
              this.innerValue.id = "1";
              this.$emit("create:item", this.innerValue);
            } else {
              this.$emit("update:item", this.innerValue);
            }
          }
        });
      },
      onCancelClick() {
        this.$refs.observer.reset();
        if (this.createMode) {
          this.$emit("delete:item");
        } else {
          this.innerValue = this.$helpers.cloneDeep(this.value);
          this.$emit("cancel:click");
        }
      },
      onEditClick() {
        this.$emit("edit:click");
      },
      onRemoveClick() {
        this.$emit("delete:item", this.innerValue.id);
      }
    }
  };
</script>

<style></style>
